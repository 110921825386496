<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-form  @submit.prevent="fetch(1)">
              <b-row>
                <b-col>
                  <b-form-input class="mr-1" v-model="filter.q"
                                placeholder="Recherche par Nom, Prénom, Email, Téléphone...."/>
                </b-col>
                <b-col>
                  <v-select :reduce="item => item.id" v-model="filter.concession_id" label="libelle" :options="$store.state.initialConfigModule.initialState.concessions" placeholder="Rechercher par concession"></v-select>
                </b-col>
                <b-col>
                  <v-select :reduce="item => item.id" v-model="filter.site_id" label="libelle" :options="$store.state.initialConfigModule.initialState.sites" placeholder="Rechercher par site"></v-select>
                </b-col>
                <b-col>
                  <v-select :reduce="item => item.id" v-model="filter.role_id" label="libelle" :options="$store.state.initialConfigModule.initialState.roles" placeholder="Rechercher par role"></v-select>
                </b-col>
                <b-col md="auto">
                  <b-button type="submit" variant="primary">Recherche</b-button>
                </b-col>
              </b-row>

             
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card

        no-body
    >

      <div  >
 
        <div  class="m-2 d-flex justify-content-between">
          <b-button
              variant="primary"
              :to="{ name: 'creer-utilisateur'}"
          >
            Créer utilisateur
          </b-button>
          <div class="align-self-center">{{pagination.total}} Utilisateurs</div>
        </div>
      </div>
      <b-table v-if="items"
               :fields="fields"
               :items="items"

               responsive
               class="mb-0"

      >
        <template #cell(actions)="data">

          <b-button :to="{ name: 'editer-utilisateur', params : {id : data.item.id }}" v-ripple.400="'rgba(113, 102, 240, 0.15)'"  size="sm" variant="outline-primary"  >
            Editer
          </b-button>
        </template>
      </b-table>



    </b-card>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div> 
  </div>

</template>

<script>

import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'


export default {
  name: "UtilisateursList",
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      fields: ['id',  'nom','prenom','email',{key : 'role.libelle', label : 'Role'}, {key : 'concession.libelle', label : 'Concession'}, {key : 'actions'  }],      /* eslint-disable global-require */
      items: [],
      pagination : {},
      filter: {
        q: ''
      },
    }
  },
  
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      let _this = this;
      _this.is_loading = true;
      this.$store.dispatch('userModule/fetchUtilisateurs', {
        page: page,
        ..._this.filter
      }).then(function (response) {
        _this.items = response.data;
        _this.pagination = response.meta;
        _this.is_loading  = false;
      })
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
